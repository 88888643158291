import { ReactComponent as BrandLogo } from "../../components/RACING/assets/logitech_mclaren_logo.svg";

export const LOGITECH_brandVariables = {
  client: "LOGITECH", // For picking wich leaderboard to show
  layoutKeyValue: "LOGITECH",
  brand: "logitech", // For scss
  gameStyle: "racing", // For picking wich leaderboard to show
  eventCode: "logitech_spillexpo_2024", // Needed for fetching correct challenge
  // eventCode: "logitech_gamebox_spring_2024", // Needed for fetching correct challenge
  challengeStatus: "ongoing",
  challengeGameType: "fm8",
  brandLogo: BrandLogo,
  leaderboardLength: 7,
  prize: {
    nameOf: "$100,000+ in cash and prizes", // will be marked in color
    typeOfItem: "", // will be added after nameOf
  },
};
