import { FC } from "react";
import { ClientProps } from "../../../../hooks/useClientData";
import { mockLeaderboardData } from "./mockLeaderboardData";
import BaseUserAvatar from "../../../../components/reusable/BaseUserAvatar";
import { formattedTime } from "../../../../helperFunctions/formatMsToTime";
import { brand } from "@/brandVariables/getBandVariables";

export const LeaderboardHasThreeScores: FC<{
  clientData: ClientProps;
}> = ({ clientData }) => {
  const winners = clientData.leaderboard.data?.slice(0, 3);
  // const winners = mockLeaderboardData.slice(0, 3);
  const isElgiganten = brand.client === "ELGIGANTEN" ? "text-gradient" : "";
  return (
    <div className="leaderboard__topThree">
      <ul className="topThree">
        {winners?.map((winner) => {
          return (
            <li>
              <BaseUserAvatar
                userName={winner.profile.userName}
                logoUrl={winner.profile.profileLogoUrl}
              />

              <div className="topThree__info">
                <p className="topThree__name">{winner.profile.userName}</p>

                <div className="topThree__meta">
                  <div className="topThree__team">
                    <p>CAR:</p>
                    <span className={isElgiganten}>
                      {/* {winner.userChallengeData.gameSpecificData?.teamName ||
                        "-"} */}
                      1976 McLaren M23
                    </span>
                  </div>
                  <div className="topThree__time">
                    <p>TIME:</p>
                    <span className={isElgiganten}>
                      {formattedTime(winner.userChallengeData.points) || "-"}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
