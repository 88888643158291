import { JBL_brandVariables } from "./brands/JBL_brandvariables";
import { LOGITECH_brandVariables } from "./brands/LOGITECH_brandvariables";
import { FORTNITE_brandVariables } from "./brands/FORTNITE_brandvariables";
import { FALLGUYS_brandVariables } from "./brands/FALLGUYS_brandvariables";
import { ELGIGANTEN_brandVariables } from "./brands/ELGIGANTEN_brandvariables";
import { JBL_CSTIMED_brandVariables } from "./brands/JBL_CS2TIMED_brandvariables";

const brandInfo = {
  "localhost:5173": ELGIGANTEN_brandVariables, // Local dev
  "localhost:5174": LOGITECH_brandVariables, // Local dev
  "iplay-leaderboard.firebaseapp.com": JBL_CSTIMED_brandVariables, // Test deployment
  "iplay-leaderboard.web.app": LOGITECH_brandVariables, //Racing leaderboard
  "fortnite-ldbrd.iplay.dk": FORTNITE_brandVariables, // Fortnite leaderboard
  "fallguys-ldbrd.iplay.dk": FALLGUYS_brandVariables, // Fallguys leaderboard
  "logitech-ldbrd.iplay.dk": LOGITECH_brandVariables, //Racing leaderboard
  "elgiganten-ldbrd.iplay.dk": ELGIGANTEN_brandVariables, //Racing leaderboard
  "jbl-ldbrd.iplay.dk": JBL_CSTIMED_brandVariables, // CS TIMED MAP
};

export const brand = brandInfo[window.location.host];
